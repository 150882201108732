import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHero from "../components/homeHero"
import media from "../components/media"
import Circles from "../components/Circles"

import homeBubble from "../images/home-bubble-ap.jpg"
import homeBubble1 from "../images/home-bubble-osteo.jpg"
import homeBubble2 from "../images/home-bubble-services.jpg"
import MenuReset from "../components/MenuReset"

const IndexPage = () => (
  <Layout solidHeader>
    <SEO title="Home" />
    <MenuReset />

    <HomeHero />

    <Intro>
      <h4>Welcome to Fully Activated</h4>
      <p>
        Fully Activated offers Osteopathy and Applied Kinesiology services to
        get you back to being your best. Go on, what are you waiting for? LIVE
        LIFE ACTIVATED.
      </p>

      <Circles
        borderTop
        items={[
          {
            to: "/osteopathy",
            title: "Osteopathy",
            src: homeBubble,
          },
          {
            to: "/applied-kinesiology",
            title: "Applied Kinesiology",
            src: homeBubble1,
          },
          {
            to: "/services",
            title: "Services",
            src: homeBubble2,
          },
        ]}
        center
      />
    </Intro>
  </Layout>
)

export default IndexPage

const Intro = styled.section`
  text-align: center;
  margin-bottom: 5em;
  h4 {
    font-family: var(--trim);
    font-size: 2.2em;
    text-align: center;
    color: var(--blue);
    margin-bottom: 0.5em;
    line-height: 1;
    ${media.down("m")} {
      font-size: 1.7em;
      padding: 0 2em;
    }
  }

  p {
    font-size: 1.2em;
    text-align: center;
    max-width: 600px;
    position: relative;
    margin: 0 auto;
    ${media.down("m")} {
      padding: 0 5%;
      font-size: 1.2em;
    }
  }
`
